import React, { useMemo } from "react"
import cx from "classnames"
import { useTranslation } from "gatsby-plugin-react-i18next"
import hhRuImage from "../../assets/icon/hh.ru.png"
import { navigate } from "gatsby"

import { workWithUs1, workWithUs2, workWithUs3 } from "../../assets/images"
import * as styles from "./vacanciesContent.module.css"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper"

const VacanciesContent = ({ location }) => {
  const isMobile = typeof window !== "undefined" && window.innerWidth <= 1023
  const { t } = useTranslation()

  const infoContent = useMemo(() => {
    const title = t("РАБОТА У НАС")
    const text = t(
      "Если ты готов к новым вызовам, общению с ТОП-уровнем бренд команд ведущих производителей " +
        "товаров, готов учиться и стать уникальным специалистом по продажам в электронной коммерции, тогда ждем " +
        "тебя в нашей команде!"
    )

    return {
      title,
      text,
      images: [
        { src: workWithUs1, fullSrc: workWithUs1 },
        { src: workWithUs2, fullSrc: workWithUs2 },
        { src: workWithUs3, fullSrc: workWithUs3 },
      ],
      anchor: "work",
      CurrentVacancies: "Наши актуальные вакансии на hh.ru",
      buttonText: "Перейти на hh.ru",
      buttonIcon: hhRuImage,
    }
  }, [t])

  const buttonUrl = process.env.REACT_APP_BUTTON_URL

  return (
    <div className={"containerAboutCompany"}>
      <div className={styles.itemContainer}>
        <a id={infoContent.anchor} href="/#">
          {""}
        </a>
        <div
          className={cx(
            styles.contentItem,
            infoContent?.type === "right" && styles.rightSide
          )}
        >
          <div className={styles.workUsSection}>
            <div className={isMobile ? styles.headingMobile : styles.heading}>
              <p>{infoContent.title}</p>
            </div>
            {!isMobile && (
              <div className={styles.isNotMobile}>
                <div className={styles.descriptionSection}>
                  <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: infoContent.text }}
                  />
                  {infoContent.CurrentVacancies && (
                    <p>{infoContent.CurrentVacancies}</p>
                  )}
                </div>
                {infoContent.buttonIcon && (
                  <button
                    className={styles.blueButton}
                    onClick={() => navigate(buttonUrl)}
                  >
                    <img src={infoContent.buttonIcon} alt="" />
                    <span>{infoContent.buttonText}</span>
                  </button>
                )}
              </div>
            )}
          </div>
          <div
            className={cx(
              styles.vacancyImages,
              infoContent?.type === "right" ? styles.notZoom : ""
            )}
          >
            <Swiper
              className={isMobile && styles.sliderBoxMobile}
              spaceBetween={5}
              slidesPerView="auto"
              pagination={true}
              modules={[Pagination]}
            >
              <SwiperSlide>
                <div className={styles.imageContainer} aria-hidden="true">
                  <img
                    className={styles.image}
                    src={infoContent.images[0].src}
                    alt=""
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.imageContainer} aria-hidden="true">
                  <img
                    className={styles.image}
                    src={infoContent.images[1].src}
                    alt=""
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.imageContainer} aria-hidden="true">
                  <img
                    className={styles.image}
                    src={infoContent.images[2].src}
                    alt=""
                  />
                </div>
              </SwiperSlide>
            </Swiper>
            {isMobile && (
              <div className={styles.isMobile}>
                <div className={styles.descriptionSection}>
                  {infoContent.title === "РАБОТА У НАС" && (
                    <div
                      className={styles.description}
                      dangerouslySetInnerHTML={{ __html: infoContent.text }}
                    />
                  )}
                  {infoContent.CurrentVacancies && (
                    <p>{infoContent.CurrentVacancies}</p>
                  )}
                </div>
                {infoContent.buttonIcon && (
                  <button
                    className={styles.blueButton}
                    onClick={() => navigate(buttonUrl)}
                  >
                    <img src={infoContent.buttonIcon} alt="" />
                    <span>{infoContent.buttonText}</span>
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default VacanciesContent
