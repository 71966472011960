// extracted by mini-css-extract-plugin
export var blueButton = "vacanciesContent-module--blueButton--8b875";
export var container = "vacanciesContent-module--container--08907";
export var contentItem = "vacanciesContent-module--contentItem--7ac39";
export var description = "vacanciesContent-module--description--cddd9";
export var descriptionSection = "vacanciesContent-module--descriptionSection--37c0d";
export var heading = "vacanciesContent-module--heading--281e2";
export var headingMobile = "vacanciesContent-module--headingMobile--3b8e4";
export var image = "vacanciesContent-module--image--85a43";
export var imageContainer = "vacanciesContent-module--image-container--828ab";
export var isMobile = "vacanciesContent-module--isMobile--a64ed";
export var isNotMobile = "vacanciesContent-module--isNotMobile--ebb96";
export var itemContainer = "vacanciesContent-module--itemContainer--905ac";
export var menuContainer = "vacanciesContent-module--menuContainer--c5d2c";
export var mobileDescSummer = "vacanciesContent-module--mobileDescSummer--986b7";
export var notZoom = "vacanciesContent-module--notZoom--ddc79";
export var rightSide = "vacanciesContent-module--rightSide--c8245";
export var sliderBoxMobile = "vacanciesContent-module--sliderBoxMobile--b15d7";
export var vacancyImages = "vacanciesContent-module--vacancy-images--e4b21";
export var workUsSection = "vacanciesContent-module--workUsSection--c3413";