import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { renderHtmlBlocks } from "../helpers/render"
import Heading from "../components/Elements/Heading"
import Breadcrumbs from "../components/Breadcrumbs"
import { getCustomStyles } from "../helpers/styles"
import VacanciesContent from "../componentsNonAdmin/VacanciesContent"
import { CardItems } from "../components"
import ContactsBlock from "../components/ContactsBlock"

const PageTemplate = ({ data: { page }, pageContext }) => {
  const seo = page?.seo
  const settings = page?.settings

  return (
    <Layout>
      <Seo
        title={seo?.title || page.title}
        description={seo?.metaDescription || page.excerpt}
        keywords={seo?.metaKeywords || ""}
      />
      {getCustomStyles(settings)}
      <article className="page" itemScope itemType="http://schema.org/Article">
        {page.title !== "О нас" && (
          <div className="page-block-container">
            <div className="page_titles">
              <Breadcrumbs pageContext={pageContext} />
              <CardItems />
              <Heading pageTitle>{page.title}</Heading>
            </div>
          </div>
        )}

        {!!page.content && (
          <section itemProp="articleBody">
            {renderHtmlBlocks(page.content)}
            <VacanciesContent />
            <ContactsBlock />
          </section>
        )}
      </article>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageById($id: String!, $language: String!) {
    page: wpPage(id: { eq: $id }) {
      content
      title
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
      seo {
        metaDescription
        metaKeywords
        title
      }
      settings {
        customStyles
        rbeaStyles
        language
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
